import request from '@/utils/request'
const baseURL = '/rc-api'
// 查询用户账务列表
export function listUserAccount (query) {
  return request({
    url: '/internal/recyclable/userAccount/list',
    baseURL,
    method: 'get',
    params: query
  })
}

// 查询用户账务详细
export function getUserAccount (id) {
  return request({
    url: '/internal/recyclable/userAccount/' + id,
    baseURL,
    method: 'get'
  })
}

// 新增用户账务
export function addUserAccount (data) {
  return request({
    url: '/internal/recyclable/userAccount',
    baseURL,
    method: 'post',
    data: data
  })
}

// 修改用户账务
export function updateUserAccount (data) {
  return request({
    url: '/internal/recyclable/userAccount',
    baseURL,
    method: 'put',
    data: data
  })
}

// 删除用户账务
export function delUserAccount (id) {
  return request({
    url: '/internal/recyclable/userAccount/' + id,
    baseURL,
    method: 'delete'
  })
}

// 导出用户账务
export function exportUserAccount (query) {
  return request({
    url: '/internal/recyclable/userAccount/export',
    baseURL,
    method: 'get',
    params: query
  })
}
