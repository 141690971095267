var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "所属用户", prop: "userId" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入所属用户" },
                model: {
                  value: _vm.form.userName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "userName", $$v)
                  },
                  expression: "form.userName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "类别 1 收入 2 提现", prop: "type" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择类别 1 收入 2 提现" },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                [
                  _c("a-select-option", { attrs: { value: "" } }, [
                    _vm._v("请选择字典生成"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label:
                  "账单流水号 类别是收入时记录回收账单流水号，类别是提现时记录提现流水号",
                prop: "accountNo",
              },
            },
            [
              _c("a-input", {
                attrs: {
                  placeholder:
                    "请输入账单流水号 类别是收入时记录回收账单流水号，类别是提现时记录提现流水号",
                },
                model: {
                  value: _vm.form.accountNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "accountNo", $$v)
                  },
                  expression: "form.accountNo",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "发生金额 收入所得时是正值，提现时是负值",
                prop: "amount",
              },
            },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入发生金额 收入所得时是正值，提现时是负值",
                },
                model: {
                  value: _vm.form.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "amount", $$v)
                  },
                  expression: "form.amount",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "发生金额后的余额", prop: "balance" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入发生金额后的余额" },
                model: {
                  value: _vm.form.balance,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "balance", $$v)
                  },
                  expression: "form.balance",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "是否删除 0正常1删除", prop: "status" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                [
                  _c("a-radio-button", { attrs: { value: "" } }, [
                    _vm._v("请选择字典生成"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }