<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="所属用户" prop="userId">
        <a-input v-model="form.userName" placeholder="请输入所属用户" />
      </a-form-model-item>
      <a-form-model-item label="类别 1 收入 2 提现" prop="type">
        <a-select placeholder="请选择类别 1 收入 2 提现" v-model="form.type">
          <a-select-option value="">请选择字典生成</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="账单流水号 类别是收入时记录回收账单流水号，类别是提现时记录提现流水号" prop="accountNo">
        <a-input
          v-model="form.accountNo"
          placeholder="请输入账单流水号 类别是收入时记录回收账单流水号，类别是提现时记录提现流水号"
        />
      </a-form-model-item>
      <a-form-model-item label="发生金额 收入所得时是正值，提现时是负值" prop="amount">
        <a-input v-model="form.amount" placeholder="请输入发生金额 收入所得时是正值，提现时是负值" />
      </a-form-model-item>
      <a-form-model-item label="发生金额后的余额" prop="balance">
        <a-input v-model="form.balance" placeholder="请输入发生金额后的余额" />
      </a-form-model-item>
      <a-form-model-item label="是否删除 0正常1删除" prop="status">
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button value="">请选择字典生成</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getUserAccount, addUserAccount, updateUserAccount } from '@/api/recyclable/userAccount'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        userId: null,
        type: null,
        accountNo: null,
        amount: null,
        balance: null,
        status: 0,
        createTime: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [{ required: true, message: '所属用户id不能为空', trigger: 'blur' }],
        type: [{ required: true, message: '类别 1 收入 2 提现不能为空', trigger: 'change' }],
        accountNo: [
          {
            required: true,
            message: '账单流水号 类别是收入时记录回收账单流水号，类别是提现时记录提现流水号不能为空',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        userId: null,
        type: null,
        accountNo: null,
        amount: null,
        balance: null,
        status: 0,
        createTime: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getUserAccount(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateUserAccount(this.form).then(response => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addUserAccount(this.form).then(response => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
